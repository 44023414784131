// @import "../../common/mixin";
$bgColor: #f0f2f5;
$width: 480px;
.loginWrap {
    height: 100%;
    background: linear-gradient(135deg, #001529 0%, #00023f 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    & > .loginForm {
        width: 84%;
        height: 760px;
        background: #ffffff;
        box-shadow: 0px 4px 60px 0px rgba(0, 0, 0, 0.1);
        border-radius: 24px;
        padding: 144px 120px;
        display: flex;
        align-items: center;
        justify-content: center;
        .login-img {
            height: 472px;
            width: 667px;
            background-image: url("./images/img-login.png");
        }
        & > .inputWrap {
            width: 368px;
            margin-right: 285px;
            text-align: center;
            .ant-btn {
                width: 100%;
            }
            .ant-input-affix-wrapper {
                padding: 0 12px;
            }
        }
    }
}
.login-form {
    text-align: left;
    .login-tips {
        span {
            color: #2262ee;
        }
    }
}
