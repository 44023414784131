$activeColor: rgb(24, 144, 255);
header.custom-theme {
    width: 100%;
    height: 65px;
    padding: 0 20px;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 2;
    background: #ffffff;
    box-shadow: 0px 1px 4px 0px rgba(0, 21, 41, 0.12);
    .flex {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        & > .doc {
            display: flex;
            align-items: center;
            margin-left: 16px;

            p,
            a {
                margin-left: 3px;
                cursor: pointer;
            }
            &.art {
                position: relative;
                .new {
                    position: absolute;
                    top: 13px;
                    right: -5px;
                }
            }
        }
    }
    .userInfo {
        height: 45px;
        line-height: 45px;
        margin-left: 20px;
        cursor: pointer;
        img {
            width: 24px;
            height: 24px;
            margin-right: 12px;
            border-radius: 50%;
        }
        img,
        p {
            display: inline-block;
            vertical-align: middle;
        }
        p {
            max-width: 6em;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
    }
}
.ant-popover-inner-content {
    padding-top: 5px;
    padding-bottom: 5px;
}
.customPopover {
    width: 8em;
    li {
        width: 100%;
        height: 2em;
        line-height: 2em;
        cursor: pointer;
        .anticon {
            margin-right: 1em;
        }
        &:hover {
            color: $activeColor;
        }
    }
}
.project-modal {
    .modal-content {
        p {
            margin: 10px 0;
            font-size: 16px;
        }
        span {
            color: blue;
        }
    }
}
