@import '../../../common/mixin';
.errorPage {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    @include flex_c;
    flex-direction: column;
    position: relative;
    overflow: hidden;
    background-image: linear-gradient(
        to right top,
        #ffffff,
        #fffeff,
        #efeef0,
        #eae9eb,
        #dededf,
    );
    .star {
        position: absolute;
        right: 0px;
        width: 3px;
        height: 3px;
        border-radius: 50%;
        // animation: animationStarBlur 2s infinite linear;
    }

    .iron-man img {
        width: 150px;
        position: absolute;
        top: 55%;
        animation: animationFlying 12s infinite linear;
    }
    .notify {
        color: #666;
        text-align: center;
        margin-bottom: 200px;
        word-break: break-all;
        white-space: break-spaces;
        h3 {
            font-size: 32px;
            margin: 30px 0;
            color: #666;
        }
        h4 a{
            display: inline-block;
            font-size: 24px;
            margin-top: 1em;
            color: var(--antd-wave-shadow-color);
        }
        button {
            padding: 10px 30px;
            border: 0;
            background: #880000;
            font-size: 18px;
            border-radius: 5px;
            color: #eee;
            outline: none;
            &:hover {
                cursor: pointer;
                background: #660000;
            }
        }
        @keyframes animationFlying {
            0% {
                left: -150px;
                transform: rotateY(180deg);
            }
            50% {
                left: 110%;
                transform: rotateY(180deg);
            }
            51% {
                transform: rotateY(0)
            }
            100% {
                left: -150px;
                transform: rotateY(0)
            }
        }

        @keyframes animationStarBlur {
            0% {
                box-shadow: 0 0 5px rgba(255, 255, 255, 0.4);
            }
            25% {
                box-shadow: 0 0 15px rgba(255, 255, 255, 0.4);
            }
            50% {
                box-shadow: 0 0 5px rgba(255, 255, 255, 0.4);
            }
            75% {
                box-shadow: 0 0 15px rgba(255, 255, 255, 0.4);
            }
            100% {
                box-shadow: 0 0 5px rgba(255, 255, 255, 0.4);
            }
        }
    }
}
