@import '../index.scss';

html,
body,
div,
span,
applet,
object,
iframe,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
section,
strong,
sub,
sup,
tt,
var,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
i,
b,
legend,
input,
button {
    // vertical-align: baseline;
    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;
    font-size: 100%;
    outline: 0;
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    // user-select:none;
}
:focus {
    outline: none;
} /*for IE*/
::-moz-focus-inner {
    border-color: transparent;
} /*for mozilla*/

input::-moz-focus-inner,
button::-moz-focus-inner {
    border: 0;
}
body {
    background: white;
    line-height: 1;
    color: #666;
    font: 14px/1.5 'PingFangSC-Regular', 'Microsoft Yahei', 'Helvetica Neue', Helvetica, Arial,
        'Hiragino Sans GB', 'Heiti SC', 'WenQuanYi Micro Hei', sans-serif;
}
html,
body {
    min-width: 1200px;
    height: 100%;
    scroll-behavior: smooth;
}
ol,
ul {
    list-style: none;
}
table {
    border-collapse: separate;
    border-spacing: 0;
}
caption,
th,
td {
    font-weight: normal;
    text-align: left;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
    content: “”;
}
blockquote,
q {
    quotes: “” “”;
}
img {
    border: none;
}
a {
    text-decoration: none;
    color: #666;
}

::-webkit-input-placeholder {
    /* WebKit browsers */
    color: #919090;
}
:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #919090;
}
::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #919090;
}
:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: #919090;
}
::-ms-clear,
::-ms-reveal {
    display: none;
}

input {
    -webkit-appearance: none;
}
input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset !important;
}
.cl_both {
    zoom: 1;
}
/*==for IE6/7 Maxthon2==*/
.cl_both:after {
    clear: both;
    content: '.';
    display: block;
    width: 0;
    height: 0;
    visibility: hidden;
}

.ant-picker-cell {
    text-align: center;
}
.ant-input-affix-wrapper {
    .ant-input {
        &:not(:last-child) {
            margin-right: 0;
        }
    }
}
.ant-input,
.ant-btn {
    height: 40px;
    border-radius: 8px;
    min-width: 88px;
    &:not(:last-child) {
        margin-right: 24px;
    }
    &.ant-btn-link {
        margin-right: 0;
        min-width: 0;
        &.btn-link-text {
            padding-left: 0;
            padding-right: 0;
            &:not(:last-of-type) {
                margin-right: 30px;
            }
        }
    }
}
.form-item-inline-alert {
    margin-bottom: 0;
    .ant-form-item-control {
        flex-direction: row;
    }
    .ant-show-help-leave,
    &.ant-form-item-with-help .ant-form-item-explain {
        line-height: 40px;
        margin-left: 8px;
    }
    .ant-show-help-item-leave-active {
        transform: translateY(0);
    }
}
.form-item-puretext {
    .ant-form-item-label > label,
    .ant-form-item-control-input {
        min-height: auto;
        height: auto;
    }
}
.ant-form-inline .ant-form-item:last-child {
    margin-right: 0;
}

.ant-form-item-required {
    height: 100%;
}
.ant-picker-content td {
    vertical-align: middle;
}
.no-remove-blank {
    white-space: pre;
    span {
        white-space: pre;
    }
}
.min-width-300 {
    min-width: 300px !important;
}
.ant-checkbox + span {
    padding-right: 0;
}

// .ant-checkbox-inner {
//     border-radius: 0px;
// }
.ant-checkbox + span {
    padding-right: 0;
}
.ant-table-content {
    @include commonScrollBar;
}
.ant-table-thead {
    .ant-table-cell {
        min-width: 100px;
        font-size: 14px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
        &::before {
            width: 0px !important;
        }
    }
}
.ant-table-tbody {
    .ant-table-cell {
        color: rgba(0, 0, 0, 0.65);
        min-width: 50px;
        vertical-align: middle;
        .index {
            text-align: center;
        }
        .current-state {
            display: flex;
            align-items: center;
            & > .light {
                display: inline-block;
                width: 6px;
                height: 6px;
                border-radius: 6px;
                margin: 8px;
            }
        }
        .app-operation {
            display: flex;
            .ant-btn {
                padding: 0 16px 0 0;
                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}
.art-table {
    .no-fold {
        white-space: nowrap;
    }
    .material {
        display: flex;
        align-items: center;
        .art-icon {
            display: inline-block;
            text-align: center;
            background: #f5f5f5;
            margin-right: 16px;
            min-width: 91px;
            width: 91px;
            height: 68px;
            border-radius: 4px;
            img {
                width: 68px;
                height: 68px;
            }
        }
        .material-text {
            min-width: 300px;
            p:nth-child(1) {
                font-size: 16px;
                font-weight: 500;
                color: rgba(0, 0, 0, 0.85);
                line-height: 24px;
                word-break: break-all;
            }
            font-size: 14px;
            font-weight: 400;
            color: rgba(0, 0, 0, 0.65);
            line-height: 24px;
            .description {
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }
    .show-details {
        cursor: pointer;
        font-size: 14px;
        font-weight: 400;
        color: #2262ee;
        line-height: 24px;
    }

    .ant-table-cell {
        vertical-align: middle;
    }
}
.ant-table-pagination {
    .ant-pagination-item,
    .ant-pagination-item-link,
    .ant-select-selector {
        border-radius: 4px !important;
    }
    & > .ant-pagination-options {
        .ant-pagination-options-quick-jumper {
            height: 32px;
            line-height: 32px;
            & > input {
                height: 32px;
                border-radius: 4px;
            }
        }

        .ant-select-selector,
        .ant-select-selection-item {
            height: 32px;
            line-height: 32px;
        }
    }
}

.ant-menu-submenu {
    &:not(.ant-menu-submenu-selected) {
        .ant-menu-submenu-title {
            svg {
                opacity: 0.65;
            }
        }
    }
}

.ant-menu-submenu-open {
    &:not(.ant-menu-submenu-selected) {
        color: rgba(255, 255, 255, 0.65) !important;
        i {
            &::before,
            &::after {
                background: rgba(255, 255, 255, 0.65) !important;
            }
        }
    }
}

.ant-menu-item:hover,
.ant-menu-item-selected {
    opacity: 1 !important;
    span {
        color: #fff;
    }
}

.ant-picker-range-arrow::before {
    width: auto;
}

.ant-modal-body {
    max-height: 80vh;
    overflow-y: auto;
    padding-right: 16px;
    margin-right: 4px;
    @include commonScrollBar;
}

.ant-picker-input > input,
.ant-select,
.ant-input,
.ant-radio-wrapper {
    color: rgba(0, 0, 0, 0.65);
}

.ant-select-selector {
    border-radius: 8px !important;
}

.margin-24 {
    margin: 24px;
}

.margin-0-24 {
    margin: 0 24px;
}

.margin-24-0 {
    margin: 24px 0;
}

.required {
    &::before {
        display: inline-block;
        color: #ff4d4f;
        font-size: 14px;
        font-family: SimSun, sans-serif;
        line-height: 1;
        content: '*';
    }
}

.text-center {
    text-align: center !important;
}

.status-text {
    i {
        width: 6px;
        height: 6px;
        display: inline-block;
        background: currentColor;
        vertical-align: middle;
        border-radius: 3px;
        margin-right: 8px;
    }
}

.loading-indicator {
    font-size: 14px;
    color: #2262ee;
    span {
        display: inline-block;
        overflow: hidden;
        height: 1em;
        line-height: 1;
        vertical-align: -0.25em;
        &::after {
            display: block;
            white-space: pre-wrap;
            content: '...\A..\A.';
            animation: loading 3s infinite step-start both;
        }
    }
}
@keyframes loading {
    33% {
        transform: translate3d(0, -2em, 0);
    }
    66% {
        transform: translate3d(0, -1em, 0);
    }
}
