@mixin scrollBar($height, $thumbColor, $trackColor, $hoverColor: rgb(209, 206, 206)) {
  &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: $height;
      height: $height;
  }
  &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: $height;
      box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.2);
      background-color: $thumbColor;
      transition: background-color 0.5s;
      cursor: pointer;
      &:hover {
          background-color: $hoverColor;
      }
  }
  &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      // box-shadow: inset 0 0 2px rgba(0,0,0,0.2);
      // border-radius: 10px;
      background: $trackColor;
  }
}
@mixin commonScrollBar($count: 8px) {
  @include scrollBar($count, #f0f2f5, rgba(250, 251, 252, 0), #aaa);
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @include commonScrollBar(5px)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
@mixin better-scroll {
  @include commonScrollBar(5px);
  margin-right: 4px;
}

.better-scroll {
  @include commonScrollBar(5px);
  margin-right: 4px;
}
