@import '../../../common/mixin';
@import '../../../index.scss';

$sideBarColor: #001529;
.ant-layout-sider {
    position: 'fixed';
    top: 0;
    left: 0;
    z-index: 5;
    background-color: $sideBarColor;
    section.logo {
        width: 149px;
        height: 120px;
        margin: 14px auto 30px auto;
        background: url('./images/logo.png') center center no-repeat;
        position: relative;
        .envtag {
            background: white;
            color: inherit;
            border-radius: 7px;
            padding: 0px 4px;
            height: 12px;
            line-height: 12px;
            font-size: 12px;
            display: inline-block;
            position: absolute;
            left: 85px;
            top: 9px;
        }
        &.admin {
            width: 198px;
            height: 120px;
            margin: 24px auto;
            color: #0b2b74;
            background: url('./images/logo_admin.png') center center no-repeat;
            .envtag {
                left: 110px;
                top: 10px;
            }
        }
    }
    .sideBar {
        // background-color: transparent;
        border-right: none;
        li {
            &:not(.ant-menu-submenu) {
                opacity: 0.65;
            }
            a {
                color: rgba(255, 255, 255, 1);
            }
        }
    }
    .collapsedBtn {
        display: block;
        height: 65px;
        line-height: 65px;
        position: absolute;
        top: 0;
        left: 100%;
        margin-left: 24px;
        font-size: 20px;
        cursor: pointer;
        &:hover {
            color: #333;
        }
    }
    &.ant-layout-sider-collapsed {
        section.logo {
            width: 72px;
            height: 62px;
            background: url('./images/logo-collapsed.png') center center/cover;
            &.admin {
                background: url('./images/logo-collapsed.png') center center/cover;
            }
            .envtag {
                left: 44px;
                top: 3px;
            }
        }
    }
}
section.ant-layout.contentWrap {
    margin-top: 65px;
    // overflow-x: auto !important;
    // overflow-y: auto;
    overflow: hidden;
    max-height: calc(100vh - 65px);
    .page-content {
        overflow: auto;
        // padding: 24px;
        @include commonScrollBar;
    }
}

.breadcrumbs {
    background: #fff;
    line-height: 54px;
    padding: 0 32px;
    color: rgba(0, 0, 0, 0.65);
    & > :not(:last-child) {
        color: rgba(0, 0, 0, 0.45);
        &:hover {
            color: rgba(0, 0, 0, 0.65);
        }
        &::after {
            content: '/';
            margin: 0 8px;
        }
    }
    & > :last-child {
        pointer-events: none;
    }
}

.ant-layout-sider.admin,
.ant-menu.admin {
    background: #0b2b74;
}
.ant-menu.admin .ant-menu-inline.ant-menu-sub {
    background: #051539;
}
